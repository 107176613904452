// IMPORT VENDOR
import $ from "jquery";
window.$ = window.jQuery = $;

import { Foundation } from "foundation-sites/js/foundation.core";
import { Reveal } from "foundation-sites/js/foundation.reveal";
Foundation.addToJquery($);
Foundation.plugin(Reveal, "Reveal");

import AOS from "aos";
import Isotope from "isotope-layout";
var jQueryBridget = require("jquery-bridget");

// IMPORT DES COMPONENTS
import { utils } from "./components/_utils";

// IMPORT DES PAGES
//import { home } from './pages/home';

// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
  $(document).foundation();

  AOS.init();

  let bodyClassList = document.getElementsByTagName("body")[0].classList;
  switch (true) {
    case bodyClassList.contains("template-page-formations"):
      formations.readyFn();
      break;
  }

  jQueryBridget("isotope", Isotope, $);
  var $grid = $(".grid-isotope").isotope({
    itemSelector: ".grid-item",
    layoutMode: "fitRows",
  });
  $("select#filters-categories, select#filters-stores").on(
    "change",
    function () {
      var category = $("select#filters-categories").val(),
        store = $("select#filters-stores").val(),
        filterValue;
      if (category == "" && store == "") filterValue = "*";
      if (category !== "" && store !== "")
        filterValue = "." + category + "." + store;
      if (category !== "" && store == "") filterValue = "." + category;
      if (category == "" && store !== "") filterValue = "." + store;
      $grid.isotope({ filter: filterValue });
    }
  );

  $('a[href="#how-to"]').on("click", function () {
    $("#reveal-how-to").foundation("open");
  });
});

// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on("load", function () {
  $("#nav-toggle").on("click", function (e) {
    e.preventDefault();
    $("#mobile-menu").slideToggle("fast");
    $("#overlay").fadeToggle("fast");
    $(this).toggleClass("active");
  });

  $("#mobile-menu a").on("click", function (e) {
    $("#mobile-menu").slideToggle("fast");
    $("#nav-toggle").toggleClass("active");
  });

  scrollFunction();
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      $("header").addClass("smaller");
    } else {
      $("header").removeClass("smaller");
    }
  }

  let bodyClassList = document.getElementsByTagName("body")[0].classList;
  switch (true) {
    case bodyClassList.contains("home"):
      home.loadFn();
      break;
  }
});

// ----------------------------------- WINDOW RESIZE ------------------------------------
// --------------------------------------------------------------------------------------
$(window).on("resize", function () {});
